import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home/Home';
import Courses from '../pages/Courses/Courses';
import Roadmap from '../pages/Roadmap/Roadmap';
import About from '../pages/About/About';
import News from '../pages/News/News';
import Support from '../pages/Support/Support';
import DeepLink from '../pages/DeepLink/DeepLink';

const AppRoutes = () => (
  <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/courses" element={<Courses />} />
    <Route path="/roadmap" element={<Roadmap />} />
    <Route path="/about" element={<About />} />
    <Route path="/news" element={<News />} />
    <Route path="/support" element={<Support />} />
    <Route path="/deeplink" element={<DeepLink />} />
  </Routes>
);

export default AppRoutes;
