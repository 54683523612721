import React from 'react';
import './Footer.css';

const Footer = () => {
  const handleEmailClick = () => {
    navigator.clipboard.writeText('info@survivalcoding.com');
    alert('이메일 주소가 클립보드에 복사되었습니다');
  };

  return (
    <footer className="footer">
      <div className="container footer-content">
        <div className="footer-section">
          <h3>오준석의 생존코딩</h3>
          <p>이 사이트는 React로 만들었습니다</p>
        </div>
        <div className="footer-section">
          <p>
            강의/비즈니스 관련 문의 : {' '}
            <span className="email" onClick={handleEmailClick}>
              info@survivalcoding.com
            </span>
          </p>
        </div>
        <div className="footer-section social-links">
          <a href="https://www.youtube.com/@survivalcoding" target="_blank" rel="noopener noreferrer">Youtube</a>
          <a href="https://www.instagram.com/survivalcoding/" target="_blank" rel="noopener noreferrer">Instagram</a>
          <a href="https://www.linkedin.com/in/%EC%A4%80%EC%84%9D-%EC%98%A4-295623b0/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://www.facebook.com/junsuk.oh.81" target="_blank" rel="noopener noreferrer">Facebook</a>
          <a href="https://www.github.com/junsuk5" target="_blank" rel="noopener noreferrer">Github</a>
          <a href="https://velog.io/@jsoh" target="_blank" rel="noopener noreferrer">Velog.io</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
