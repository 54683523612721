import React from "react";
import { Link } from "react-router-dom";
import { logEvent } from "firebase/analytics"; // Firebase Analytics import
import { analytics } from "../../firebaseConfig"; // Firebase 설정 파일 import
import "./Header.css";

const Header = () => {
  const handleLinkClick = (menuName) => {
    logEvent(analytics, 'menu_click', {
      menu_name: menuName,
    });
  };

  return (
    <header>
      <div className="container header-container">
        <Link to="/" className="logo">
          오준석의 생존코딩
        </Link>
        <nav>
          <ul className="nav-links">
            <li>
              <Link to="/courses" onClick={() => handleLinkClick("강의/컨설팅")}>
                강의/컨설팅
              </Link>
            </li>
            <li>
              <a
                href="https://www.inflearn.com/roadmaps/29"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleLinkClick("학습로드맵")}
              >
                학습로드맵
              </a>
            </li>
            <li>
              <Link to="/about" onClick={() => handleLinkClick("이력")}>
                이력
              </Link>
            </li>
            <li>
              <a
                href="https://youtube.com/@survivalcoding"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => handleLinkClick("YouTube")}
              >
                YouTube
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </header>
  );
};

export default Header;
