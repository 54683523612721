// src/App.js
import React, { useEffect } from "react";
import { BrowserRouter as Router, useLocation } from "react-router-dom";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
import AppRoutes from "./routes/AppRoutes";
import "./App.css"; // CSS 파일을 추가합니다.
import { analytics } from "./firebaseConfig"; // Firebase 설정 파일 import
import { logEvent } from "firebase/analytics";
import Chat from "./components/Chat/Chat"; // Chat 컴포넌트 import

const App = () => {
  const location = useLocation();

  useEffect(() => {
    // 페이지 뷰 이벤트 기록
    logEvent(analytics, "page_view", {
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <div className="App">
      <Header />
      <main>
        <AppRoutes />
        <Chat /> {/* Chat 컴포넌트 추가 */}
      </main>
      <Footer />
    </div>
  );
};

const AppWrapper = () => (
  <Router>
    <App />
  </Router>
);

export default AppWrapper;
