import React, { useState } from "react";
import "./Chat.css"; // 스타일을 위한 CSS 파일 추가

const Chat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const toggleChat = () => {
    setIsOpen(!isOpen);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const webhookURL =
      "https://discord.com/api/webhooks/1298166373163335680/Z7-FA6JyXcK3flwIk31Uu11jnu12-ehuqywuZMJNu5jOHqR0MQbcP1cciPkDX7dFLAhY"; // Discord Webhook URL

    fetch(webhookURL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        content: `이메일: ${email}\n메시지: ${message}`,
      }),
    })
      .then((response) => {
        if (response.ok) {
          alert("메시지가 전송되었습니다!");
          setEmail("");
          setMessage("");
          toggleChat(); // 채팅창 닫기
        } else {
          alert("메시지 전송에 실패했습니다.");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        alert("메시지 전송에 실패했습니다.");
      });
  };

  return (
    <>
      <div className="chat-icon" onClick={toggleChat}>
        💬
      </div>
      {isOpen && (
        <div className="chat-window">
          <div className="chat-header">
            <p>문의 사항을 작성해 주시면 확인 후 답변 드리겠습니다.</p>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="email"
              placeholder="이메일 주소 입력"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <textarea
              placeholder="메시지 입력"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              required
            />
            <button type="submit">전송</button>
          </form>
        </div>
      )}
    </>
  );
};

export default Chat;
