import React from 'react';
import './Home.css';
import booksData from '../../assets/data/books.json';
import coursesData from '../../assets/data/courses.json';

// 이미지 컨텍스트 생성
const imageContext = require.context('../../assets/images', false, /\.(webp|png|jpe?g)$/);

const Home = () => {
  // 이미지 URL을 가져오는 함수
  const getImageUrl = (imageName) => {
    try {
      return imageContext(`./${imageName}`);
    } catch (error) {
      console.error(`Image not found: ${imageName}`);
      return ''; // 또는 기본 이미지 URL
    }
  };

  // Flutter와 Dart 카테고리의 강좌만 필터링
  const flutterCourses = coursesData.filter(course => 
    course.category === 'Flutter' || course.category === 'Dart'
  );

  // Android 카테고리의 강좌만 필터링
  const androidCourses = coursesData.filter(course => 
    ['Android', 'Java', 'Kotlin'].includes(course.category)
  );

  // Git 카테고리의 강좌만 필터링
  const basicCourses = coursesData.filter(course => 
    course.category === 'Git'
  );

  // 강좌를 렌더링하는 함수
  const renderCourses = (courses, title) => (
    <section className="courses">
      <div className="container">
        <h2 className="section-title">{title}</h2>
        <div className="course-list">
          {courses.map(course => (
            <a key={course.title} href={course.link} target="_blank" rel="noopener noreferrer" className="course-item">
              <div className="course-card">
                <img src={getImageUrl(course.thumbnailUrl)} alt={course.title} className="course-image" />
                <div className="course-info">
                  <span className="course-level">{course.description}</span>
                  <h3 className="course-title">{course.title}</h3>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  );

  return (
    <>
      <section className="hero">
        <div className="container">
          <h1>오준석의 생존코딩</h1>
          <div className="hero-content">
            <p className="hero-description">📚 교육하고 책 쓰는 개발자</p>
            <ul className="hero-details">
              <li>🧑‍🏫 프로그래밍 멘토</li>
              <li>💻 개발 경력 15+, 교육 경력 10+</li>
              <li>📱 Android, Flutter 강의, 튜토리얼</li>
            </ul>
          </div>
          {/* 강의 보기 버튼 제거 */}
        </div>
      </section>

      <section className="books">
        <div className="container">
          <h2 className="section-title">저서</h2>
          <div className="book-list">
            {booksData.map(book => (
              <a key={book.title} href={book.link} target="_blank" rel="noopener noreferrer" className="book-item">
                <div className="book-card">
                  <img src={getImageUrl(book.thumbnailUrl)} alt={book.title} className="book-image" />
                  <h3 className="book-title">{book.title}</h3>
                </div>
              </a>
            ))}
          </div>
        </div>
      </section>

      {renderCourses(flutterCourses, "Flutter")}
      {renderCourses(androidCourses, "Android")}
      {renderCourses(basicCourses, "기본기")}
    </>
  );
};

export default Home;
