import React from "react";
import "./Courses.css";
import coachingImage from "../../assets/images/coaching.webp"; // 이미지 import

const Courses = () => {
  // 모집 중인 강의 데이터 (1개로 변경)
  const upcomingCourses = [
    {
      title: "코틀린 Jetpack Compose 기반의 안드로이드 개발자 과정",
      description:
        "코틀린과 Jetpack Compose를 이용한 안드로이드 앱 개발 과정입니다",
      status: "모집중",
      link: "https://sesac.seoul.kr/course/active/detail.do?courseActiveSeq=2851&srchCategoryTypeCd=&courseMasterSeq=1484&currentMenuId=900002030",
      image:
        "https://sesac.seoul.kr/storage/image/temp/2024/10/16/qIsrV9MkPLcKMY0FDNy3.jpg", // 이미지 URL을 위한 placeholder
    },
  ];

  // 진행 중인 강의 데이터
  const ongoingCourses = [
    {
      title: "Flutter 기반 앱 개발자 양성 과정",
      description: "Flutter를 이용한 모바일 앱 개발 기초 과정입니다.",
      status: "진행중",
      link: "https://sesac.seoul.kr/course/active/detail.do?courseActiveSeq=1678&srchCategoryTypeCd=&courseMasterSeq=253&currentMenuId=900002001",
    },
  ];

  return (
    <div className="courses-container">
      {/* 모집 중인 강의 정보 표시 */}
      <div className="course-status">
        <h2>현재 모집 중인 강의</h2>
        <ul className="upcoming-courses-list">
          {upcomingCourses.map((course, index) => (
            <li key={index} className="course-item">
              <div className="image-container">
                <img src={course.image} alt={course.title} />
              </div>
              <div className="course-content">
                <h3>{course.title}</h3>
                <p>{course.description}</p>
                <span className="status">{course.status}</span>
                <div className="button-container">
                  <a href={course.link} className="cta-button">
                    자세히 보기
                  </a>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* 진행 중인 강의 정보 표시 */}
      <div className="ongoing-courses">
        <h2>현재 진행 중인 강의</h2>
        <div className="course-cards">
          {ongoingCourses.map((course, index) => (
            <div key={index} className="course-card ongoing">
              <h3>{course.title}</h3>
              <p>{course.description}</p>
              <span className="status">{course.status}</span>
              <div className="button-container">
                <a href={course.link} className="cta-button">
                  자세히 보기
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className="courses-content">
        <section className="youtube-section">
          <div className="content">
            <h2>유튜브 멤버십</h2>
            <p>
              유튜브 채널 멤버로 가입하시면 다음과 같은 혜택을 받으실 수
              있습니다:
            </p>
            <ul>
              <li>전용 Discord 채널 초대</li>
              <li>가입자 전용 채널에서 질문 우선 답변</li>
              <li>다른 개발자들과의 교류 기회</li>
            </ul>
            <a
              href="https://www.youtube.com/@survivalcoding"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button"
            >
              유튜브 채널 가입하기
            </a>
          </div>
          <div className="emoji-container">
            <span role="img" aria-label="유튜브" className="large-emoji">
              🎥
            </span>
          </div>
        </section>

        <section className="consulting-section">
          <div className="content">
            <h2>1:1 전문 컨설팅</h2>
            <p>
              더 전문적이고 개인화된 컨설팅이 필요하신가요? 1:1 코칭을 통해
              여러분의 개발 여정을 지원해 드립니다.
            </p>
            <ul>
              <li>상담 후 맞춤형 1:1 코칭 제공</li>
              <li>1시간 기준 10만원</li>
              <li>1주일간 Discord를 통해 무제한 질문 가능</li>
            </ul>
            <a
              href="https://forms.gle/5Q4csmayZx2kCQWd6"
              target="_blank"
              rel="noopener noreferrer"
              className="cta-button"
            >
              상담 신청하기
            </a>
          </div>
          <div className="image-container">
            <img
              src={coachingImage}
              alt="원격 코칭"
              className="coaching-image"
            />
          </div>
        </section>

        <section className="faq-section">
          <h2>자주 묻는 질문</h2>
          <div className="faq-grid">
            <div className="faq-item">
              <h3>
                <span role="img" aria-label="질문">
                  ❓
                </span>{" "}
                누구에게 추천하나요?
              </h3>
              <ul>
                <li>맞춤형 커리큘럼이 필요한 분</li>
                <li>1대1 맞춤식 서포트를 원하시는 분</li>
                <li>특정 문제 해결에 도움이 필요한 분</li>
              </ul>
            </div>
            <div className="faq-item">
              <h3>
                <span role="img" aria-label="준비물">
                  🖥️
                </span>{" "}
                준비물은 무엇인가요?
              </h3>
              <p>인터넷이 연결된 PC와 마이크, 웹캠이 필요합니다.</p>
            </div>
            <div className="faq-item">
              <h3>
                <span role="img" aria-label="일정">
                  📅
                </span>{" "}
                강의 일정은 어떻게 되나요?
              </h3>
              <p>강의 일정은 수업 전에 상호 협의하여 결정합니다.</p>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default Courses;
