import React from "react";
import "./DeepLink.css";

const DeepLink = () => {
  const handleOpenApp = () => {
    const deepLinkUrl = "https://survivalcoding.com/detail/577";    

    window.location.href = deepLinkUrl;
  };

  return (
    <div className="deeplink-container">
      <button className="open-app-button" onClick={handleOpenApp}>
        앱 열기
      </button>
    </div>
  );
};

export default DeepLink;
