import React, { useState } from "react";
import "./About.css";
import profileImage from "../../assets/images/my_pic.webp";
import awardImage1 from '../../assets/images/awardImage1.jpg';
import awardImage2 from '../../assets/images/awardImage2.png';

const ImageModal = ({ src, alt, onClose }) => (
  <div className="modal-overlay" onClick={onClose}>
    <div className="modal-content" onClick={(e) => e.stopPropagation()}>
      <img src={src} alt={alt} />
    </div>
  </div>
);

const About = () => {
  const [modalImage, setModalImage] = useState(null);

  const openModal = (src, alt) => {
    setModalImage({ src, alt });
  };

  const closeModal = () => {
    setModalImage(null);
  };

  return (
    <div className="about-container">
      <section className="about-hero">
        <div className="profile-image-container">
          <img src={profileImage} alt="오준석" className="profile-image" />
        </div>
        <div className="hero-content">
          <h1>
            오준석 <span className="subtitle">교육하고 책 쓰는 개발자</span>
          </h1>
          <p className="tagline">생존코딩 오준석</p>
          <p>프로그래밍 멘토 | 개발 경력 16+🖥, 교육 경력 11+📖</p>
        </div>
      </section>

      <section className="about-section">
        <h2>주요 기술스택</h2>
        <ul>
          <li>언어 : Java, Kotlin, Dart, JavaScript</li>
          <li>주력 프레임워크 : Flutter, Android</li>
          <li>기타 : SQL, Firebase, Git, Docker, Angular, React, Vue.js</li>
          <li>이외에도 풀 스택으로 다양한 경험 보유</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>주요경력</h2>
        <ul>
          <li>2023. 7~ 현재 오렌지(OhRange) 대표</li>
          <li>2023. 1~ 현재 주식회사 금왕 이사</li>
          <li>2018. 3~ 2022. 12 세민직업전문학교 부원장</li>
          <li>2014. 9~ 2018. 1 수원스마트앱개발학원 원장</li>
          <li>2012. 9~2014.9 LG전자 MC사업부 A실 선임연구원</li>
          <li>2008. 2~2010.4 일본 아이치현 (株)日本テ쿠시ード IT사업부</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>교육이력</h2>
        <ul>
          <li>2024 한국폴리텍대학 광명융합기술교육원 증강현실시스템과 : 웹 프로그래밍</li>
          <li>2024 서울경제진흥원 청년취업사관학교 영등포 새싹 6기 : Flutter 앱 개발자 양성 과정</li>
          <li>2024 모두의 연구소 : Flutter 모바일 앱 과정 2기</li>
          <li>2023 모두의 연구소 : Flutter 모바일 앱 과정 1기</li>
          <li>2023 LG CNS : 코틀린 특강</li>
          <li>2023 한국폴리텍대학 광명융합기술교육원 : 증강현실시스템과 - 웹기초 교육</li>
          <li>2023 동국대학교 특강 : 제품/서비스 개발의 이해</li>
          <li>2023 한양대 Erica 방학 특강 : Flutter 왕초보 앱 개발</li>
          <li>2023 서울경제진흥원 청년취업사관학교 새싹 5기 : Flutter 앱 개발자 양성 과정</li>
          <li>2023 SKT 경력사원 안드로이드 보수 교육</li>
          <li>2023 SKT 신입사원 코틀린, 안드로이드 교육</li>
          <li>2023 폴리텍대학 광명융합기술교육원 Java 교육</li>
          <li>2023 코드스테이츠 Flutter 핸즈온</li>
          <li>2023 카카오 안드로이드 신입 교육</li>
          <li>2022 SKT 서비스직군의 개발자 대상 "Flutter로 구현하는 모바일 app개발"</li>
          <li>2022 기업 비긴메이트 Flutter 교육</li>
          <li>2022 신한금융지주사 PO 양성과정</li>
          <li>2022 경복대학교 Flutter 특강</li>
          <li>2022 서울산업진흥원 청년취업사관학교 새싹 4기 : Flutter 기반 멀티플랫폼 앱 개발자 데뷔 과정</li>
          <li>2022 한국폴리텍대학 분당융합기술교육원 데이터융합SW학과 웹 기초 강의</li>
          <li>2022 한국폴리텍대학 분당융합기술교육원 임베디드과 자바 강의</li>
          <li>2022 카카오 안드로이드 신입 개발자 교육</li>
          <li>2021 서울산업진흥원 청년취업사관학교 싹 2기 : Flutter 기반 멀티플랫폼 앱 개발자 데뷔 과정</li>
          <li>2021 KT 미래인재육성 3기 AI/SW개발 Flutter 교육</li>
          <li>2021 한국폴리텍대학 광명융합기술교육원 3D제품설계과 Arduino 강의</li>
          <li>2021 한국폴리텍대학 분당융합기술교육원 데이터융합SW학과 웹 기초 강의</li>
          <li>2021 세민직업전문학교 Flutter 2기 강의</li>
          <li>2021 카카오 안드로이드 신입 개발자 교육</li>
          <li>2020 카카오 모빌리티 클라이언트(Android) 주니어 경력 개발자 교육</li>
          <li>2020 서울산업진흥원 청년취업사관학교 싹 1기 : <a href="https://ssac.seoul.kr/course/course_view.jsp?id=20799&cid=5767&ch=course" target="_blank" rel="noopener noreferrer">Flutter 기반 멀티플랫폼 모바일 앱 개발자 데뷔 과정</a></li>
          <li>2020 세민직업전문학교 Flutter 1기 강의</li>
          <li>2020 한국폴리텍대학 광명융합기술교육원 3D제품설계과 Arduino 강의</li>
          <li>2020 한국폴리텍대학 분당융합기술교육원 데이터융합SW학과 Java 강의</li>
          <li>2019 대전 대덕중학교 '모바일 앱 개발자 진로 특강'</li>
          <li>2019 인천전자마이스터고등학교 정보기술 기능영재반 코틀린 강의</li>
          <li>2019 삼성전자 CS아카데미 '모바일 전문지식' 교육 'OS의 이해' 강의</li>
          <li>2019 샐러리업 엑스퍼트 '코틀린 안드로이드 강의'</li>
          <li>2019 대림대학교 대림대학교 DevOps엔지니어양성반 안드로이드 특강</li>
          <li>2019 대림대학교 모바일인터넷과 안드로이드 강의</li>
          <li>2019 대림대학교 '개발 공부 방법' 특강</li>
          <li>2018 스타트업캠퍼스 멘토링 교육</li>
          <li>2017 홍익대학교 창업보육센터 모바일패키지 모바일앱개발 과정 강의</li>
          <li>2015 아주대학교 안드로이드 출강</li>
          <li>2014~2018 수원스마트앱개발학원 원장 (안드로이드 개발자 양성 교육)</li>
          <li>2012 기업 안드로이드 출강</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>프로젝트</h2>
        <h3>Android</h3>
        <ul>
          <li><a href="https://play.google.com/store/apps/developer?id=js%20Oh" target="_blank" rel="noopener noreferrer">주요 개발 앱 1</a></li>
          <li><a href="https://play.google.com/store/apps/developer?id=jsOh.dev" target="_blank" rel="noopener noreferrer">주요 개발 앱 2</a></li>
          <li><a href="https://play.google.com/store/apps/details?id=com.crewmaps.crewmaps" target="_blank" rel="noopener noreferrer">주요 개발 앱 3</a></li>
        </ul>
        <h3>iOS</h3>
        <ul>
          <li><a href="https://apps.apple.com/kr/developer/js-oh/id422786649" target="_blank" rel="noopener noreferrer">iOS 앱 개발자 페이지</a></li>
        </ul>
      </section>

      <section className="about-section">
        <h2>서비스</h2>
        <ul>
          <li><a href="https://survivalcoding.com/" target="_blank" rel="noopener noreferrer">오준석의 생존코딩</a></li>
          <li><a href="https://alimipro.com/intro" target="_blank" rel="noopener noreferrer">알리미프로</a></li>
          <li><a href="https://www.youtube.com/channel/UCz9n4yRsYYryRjrSCK0-YWA" target="_blank" rel="noopener noreferrer">유튜브 생존코딩 채널</a></li>
        </ul>
      </section>

      <section className="about-section">
        <h2>발표</h2>
        <ul>
          <li>2019 IBM DevDay 행사 : 안드로이드 iOS를 한 번에 개발하는 Flutter</li>
          <li>2019 GDG 수원 행사 : Flutter 사용기</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>수상 및 행사참가</h2>
        <ul>
          <li>2023 서울시장표창</li>
          <li>2016 Google HackFair (Maker Fair)</li>
          <li>2015 Google HackFair</li>
          <li>2015 함께서울 앱 공모전 3차 본선 입상</li>
          <li>2010 제1회 대한민국 앱 공모전 우수상</li>
        </ul>
        <div className="award-images">
          <img 
            src={awardImage1} 
            alt="2010 앱개발 우수상" 
            onClick={() => openModal(awardImage1, "2010 앱개발 우수상")}
          />
          <img 
            src={awardImage2} 
            alt="수상 이미지" 
            onClick={() => openModal(awardImage2, "수상 이미지")}
          />
        </div>
      </section>

      <section className="about-section">
        <h2>기타이력</h2>
        <ul>
          <li>2020 예스24 <a href="http://www.yes24.com/eWorld/EventWorld/Event?eventno=191595" target="_blank" rel="noopener noreferrer">월간개발자 10월호</a></li>
          <li>2012 교육매거진 어울놀이 3월호. 출시 앱 소개</li>
          <li>2011 국가지식포털 웹진 10월호 U-쾌한 데이트 코너 인터뷰</li>
          <li>2011 년~ 현재. 주식회사 금왕 CTO</li>
          <li>2010 년~ 현재. 모바일 앱 개인 개발자로 활동 중</li>
          <li>지식iN 안드로이드 분야 등급 : 고수 (ID : mobileadm)</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>자격사항</h2>
        <ul className="qualifications-list">
          <li>SCJP (Sun Certified Java Programmer)</li>
          <li>정보처리기사</li>
          <li>사무자동화산업기사</li>
          <li>PC정비사 2급</li>
          <li>MCSE 엑셀</li>
          <li>MCSE 파워포인트</li>
          <li>워드프로세서 1급</li>
          <li>인터넷 정보검색사 2급</li>
          <li>JLPT(일본어능력시험) N1</li>
          <li>직업능력개발훈련교사 정보기술개발 3급</li>
          <li>직업능력개발훈련교사 정보기술운영관리 3급</li>
          <li>직업능력개발훈련교사 정보기술전략계획 3급</li>
          <li>직업능력개발훈련교사 문화콘텐츠 3급</li>
          <li>직업능력개발훈련교사 디자인 3급</li>
          <li>직업능력개발훈련교사 영상제작 3급</li>
          <li>직업능력개발훈련교사 총무일반사무 3급</li>
        </ul>
      </section>

      <section className="about-section">
        <h2>소셜</h2>
        <ul className="social-links">
          <li><a href="https://www.youtube.com/@survivalcoding" target="_blank" rel="noopener noreferrer">YouTube</a></li>
          <li><a href="https://velog.io/@jsoh/" target="_blank" rel="noopener noreferrer">Velog</a></li>
          <li><a href="https://github.com/junsuk5" target="_blank" rel="noopener noreferrer">GitHub</a></li>
          <li><a href="https://www.facebook.com/junsuk5" target="_blank" rel="noopener noreferrer">Facebook</a></li>
        </ul>
      </section>

      {modalImage && (
        <ImageModal
          src={modalImage.src}
          alt={modalImage.alt}
          onClose={closeModal}
        />
      )}
    </div>
  );
};

export default About;
